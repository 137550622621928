import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { StyledCardWrap, StyledForm, StyledFormBack, StyledSmallForm } from "../components/Form";
import Switch from "react-switch";
import { changeVE, changePT, changeVT, changeTimer, changeIQEnable, changeIntroEnable } from "../redux/actions";
import { Row, Col } from "react-bootstrap";

const Setting = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [vgSwitch, setVGSwitch] = useState(false);
  const [ptSwitch, setPTSwitch] = useState(false);
  const [timerSwitch, setTimerSwitch] = useState(false);
  const [voiceTypeSwitch, setVoiceTypeSwitch] = useState(true);
  const [iqEnable, setIQEnable] = useState(false);
  const [introEnable, setIntroEnable] = useState(false);


  const handleVGSwitch = async () => {
    setVGSwitch(!vgSwitch);
    dispatch(changeVE());
  };

  const handlePTSwitch = () => {
    setPTSwitch(!ptSwitch);
    dispatch(changePT());
  };

  const handleTimerSwitch = () => {
    setTimerSwitch(!timerSwitch);
    dispatch(changeTimer());
  };

  const handleVTSwitch = () => {
    setVoiceTypeSwitch(!voiceTypeSwitch);
    dispatch(changeVT());
  };
  
  const handleIQSwitch = () => {
    setIQEnable(!iqEnable);
    dispatch(changeIQEnable());
  };

  
  const handleintroEnable = () => {
    setIntroEnable(!introEnable);
    dispatch(changeIntroEnable());
  };


  useEffect(() => {
    if (user) {
      setVGSwitch(user.voiceEnable);
      setPTSwitch(user.processTips);
      setTimerSwitch(user.processTimer);
      setVoiceTypeSwitch(user.voiceType);
      setIQEnable(user.iqEnable);
      setIntroEnable(user.introEnable);
    }
  }, [user]);

  return (
    <StyledFormBack>
      <StyledSmallForm>
        <Row>
          <Col>
              <h2 className="text-center m-4">Settings</h2>

              <StyledCardWrap>

              <div className="card position-relative">
                <div className="card-header">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item setting-item">
                      Voice guidance 
                      <span>
                        <Switch
                          onColor="#2A88BF"
                          offColor="#E8ECEF"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={handleVGSwitch}
                          checked={vgSwitch}
                        />
                      </span>
                    </li>

                    <li className="list-group-item setting-item">
                     Process tips 
                      <span>
                        <Switch
                          onColor="#2A88BF"
                          offColor="#E8ECEF"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={handlePTSwitch}
                          checked={ptSwitch}
                        />
                      </span>
                    </li>

                    <li className="list-group-item setting-item">
                      Process timer 
                      <span>
                        <Switch
                          onColor="#2A88BF"
                          offColor="#E8ECEF"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={handleTimerSwitch}
                          checked={timerSwitch}
                        />
                      </span>
                    </li>

                    <li className="list-group-item setting-item">
                     Voice {voiceTypeSwitch ? "(Male)" : "(Female)"}
                      <span>
                        <Switch
                          onColor="#2A88BF"
                          offColor="#E8ECEF"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={handleVTSwitch}
                          checked={!voiceTypeSwitch}
                        />
                      </span>
                    </li>

                    <li className="list-group-item setting-item">
                     Integration Questions
                      <span>
                        <Switch
                          onColor="#2A88BF"
                          offColor="#E8ECEF"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={handleIQSwitch}
                          checked={iqEnable}
                        />
                      </span>
                    </li>
                    <li className="list-group-item setting-item">
                     Instructions
                      <span>
                        <Switch
                          onColor="#2A88BF"
                          offColor="#E8ECEF"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={handleintroEnable}
                          checked={introEnable}
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              </StyledCardWrap>
              <Link to="/" className="btn bg-custom border-0 my-2">
                Back
              </Link>
          </Col>
        </Row>
      </StyledSmallForm>
    </StyledFormBack>
  );
};

export default Setting;
