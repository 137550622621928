import styled from "styled-components";
import { ReactComponent as CheckSvg } from "../assets/images/checkmark.svg";


export const CheckText = (props: any) => {
  const StyledCheckText = styled.div`
    display: flex;
    gap: 10px;
    align-items: baseline;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.text};
    margin-bottom: 20px;

    svg{
      color: ${({ theme }) => theme.logo};
    }
  `;

  return (
    <StyledCheckText>
      <CheckSvg />
      {props.text}
    </StyledCheckText>
  );
};
