import * as API from "../../api";
import { addAuthUser, authUserLoading, changeVoiceEnable, changeProcessTips, changeProcessTimer, changeVoiceType, saveGoal, saveDeadline, changeIntegrationQuesEnable, changeInstructionsEnable } from "../features/auth/authSlice";

export function getUserProfile() {
  return async function (dispatch) {
    try {
      dispatch(authUserLoading({ loading: true }));
      const response = await API.getUserProfile();

      const user = response.data?.user;

      // Add authenticated user to redux store
      dispatch(addAuthUser({ user }));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(authUserLoading({ loading: false }));
    }
  };
}

export function changeVE() {
  return async function (dispatch) {
    try {
      const response = await API.changeVE();
      const voiceEnable = response.data?.voiceEnable;
      // Add authenticated user to redux store
      dispatch(changeVoiceEnable({ voiceEnable }));
    } catch (error) {
      console.log(error);
    }
  }
}

export function changePT() {
  return async function (dispatch) {
    try {
      const response = await API.changePT();
      const processTips = response.data?.processTips;
      // Add authenticated user to redux store
      dispatch(changeProcessTips({ processTips }));
    } catch (error) {
      console.log(error);
    }
  }
}

export function changeTimer() {
  return async function (dispatch) {
    try {
      const response = await API.changeTimer();
      const processTimer = response.data?.processTimer;
      // Add authenticated user to redux store
      dispatch(changeProcessTimer({ processTimer }));
    } catch (error) {
      console.log(error);
    }
  }
}

export function changeVT() {
  return async function (dispatch) {
    try {
      const response = await API.changeVoiceType();
      const voiceType = response.data?.voiceType;
      // Add authenticated user to redux store
      dispatch(changeVoiceType({ voiceType }));
    } catch (error) {
      console.log(error);
    }
  }
}


export function changeIQEnable() {
  return async function (dispatch) {
    try {
      const response = await API.changeIQEnable();
      const iqEnable = response.data?.iqEnable;
      // Add authenticated user to redux store
      dispatch(changeIntegrationQuesEnable({ iqEnable }));
    } catch (error) {
      console.log(error);
    }
  }
}


export function changeIntroEnable() {
  return async function (dispatch) {
    try {
      const response = await API.changeIntroEnable();
      const introEnable = response.data?.introEnable;
      console.log("introEnable", introEnable);
      // Add authenticated user to redux store
      dispatch(changeInstructionsEnable({ introEnable }));
    } catch (error) {
      console.log(error);
    }
  }
}


export function saveGoal_10F(goal) {
  return async function (dispatch) {
    try {
      const response = await API.saveGoal({goal: goal});
      // Add authenticated user to redux store
      dispatch(saveGoal({ goal }));
    } catch (error) {
      console.log(error);
    }
  }
}

export function saveDeadline_10F(deadline) {
  return async function (dispatch) {
    try {
      const response = await API.saveDeadline({deadline: deadline});
      // Add authenticated user to redux store
      dispatch(saveDeadline({ deadline }));
    } catch (error) {
      console.log(error);
    }
  }
}