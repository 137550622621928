import React, { useEffect, useState } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "./redux/store";
import httpInterceptors from "./utils/httpClient/interceptors";
import { changeTheme } from "./redux/features/auth/authSlice";
import { ProSidebarProvider } from "react-pro-sidebar";
import { RouterProvider, BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { router } from "./routes";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, ThemeStyles } from "./theme";
import { default as ToggleSwitch } from "react-switch";

import "bootstrap/dist/css/bootstrap.min.css";
/*
import { GoogleOAuthProvider } from "@react-oauth/google";
*/

import "./assets/style.css";

httpInterceptors.attach(store); // Pass store

function App() {
  const dispatch = useDispatch();
  const theme = useSelector((state: any) => state.auth.theme);

  // useEffect(() => {
  //   const t = localStorage.getItem("theme");

  //   if (t !== null) {
  //     dispatch(changeTheme({ theme: (t==='light')?true:false }));
  //   }
  // }, []);


  return (
    <ThemeProvider theme={theme ? lightTheme : darkTheme}>
      {/*
      <GoogleOAuthProvider  clientId="1038166718512-30l4m8jv8a32p3fp3iufbqnjk5n2jfkt.apps.googleusercontent.com">
      </GoogleOAuthProvider>
  */}
      <ThemeStyles />
      <ProSidebarProvider>
        <RouterProvider router={router} />
        {/*
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="/chat" element={<Chat />} />
            <Route path="/chat/box" element={<ChatBox />} />
          </Routes>
        </BrowserRouter>
        */}
      </ProSidebarProvider>
    </ThemeProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
