import http from "../../utils/httpClient";

/**
 * Fetch Authenticated User Profile API endpoint
 * @returns {Promise} - Axios promise object
 */
export const getUserProfile = () =>
  http.get(`/me`, { requireAuthHeader: true });

export const saveChatLog = (data) =>
  http.post(`/savechat`, data, { requireAuthHeader: true });

export const changeVE = (data) =>
  http.post(`/changeVE`, data, { requireAuthHeader: true });

export const changePT = (data) =>
  http.post(`/changePT`, data, { requireAuthHeader: true });

export const changeIQEnable = (data) =>
  http.post(`/changeIQEnable`, data, { requireAuthHeader: true });

export const changeIntroEnable = (data) =>
  http.post(`/changeIntroEnable`, data, { requireAuthHeader: true });


export const changeTimer = (data) =>
  http.post(`/changeTimer`, data, { requireAuthHeader: true });

export const checkThriveCart = () =>
  http.get(`/checkThriveCart`, { requireAuthHeader: true });

export const checkChargebee = () =>
  http.get(`/checkChargebee`, { requireAuthHeader: true });

export const changeVoiceType = (data) =>
  http.post(`/changeVoiceType`, data, { requireAuthHeader: true });

export const saveGoal = (data) =>
  http.post(`/saveGoal`, data, { requireAuthHeader: true });

export const saveDeadline = (data) =>
  http.post(`/saveDeadline`, data, { requireAuthHeader: true });
