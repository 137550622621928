import { createSlice } from "@reduxjs/toolkit";

const t = localStorage.getItem("theme");

const initialState = {
  user: null,
  token: undefined,
  user_loading: false, // `user` info is being processed [e.g Login]
  token_loading: false, // when `token` info is being processed [e.g refreshing auth]
  theme: t === "light" ? true : false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addAuthToken(state, action) {
      const { payload } = action;
      return {
        ...state,
        token: payload?.token,
      };
    },
    changeTheme(state, action) {
      const { payload } = action;
      return {
        ...state,
        theme: payload.theme,
      };
    },
    changeVoiceEnable(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, voiceEnable: payload.voiceEnable },
      };
    },
    changeProcessTips(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, processTips: payload.processTips },
      };
    },
    
    changeIntegrationQuesEnable(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, iqEnable: payload.iqEnable },
      };
    },
    
    changeInstructionsEnable(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, introEnable: payload.introEnable },
      };
    },
    changeProcessTimer(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, processTimer: payload.processTimer },
      };
    },
    changeVoiceType(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, voiceType: payload.voiceType },
      };
    },
    saveGoal(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, goal: payload.goal },
      };
    },
    saveDeadline(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, deadline: payload.deadline },
      };
    },
    addAuthUser(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: { ...state.user, ...payload.user },
      };
    },
    authUserLoading(state, action) {
      const { payload } = action;
      return {
        ...state,
        user_loading: Boolean(payload?.loading),
      };
    },
    authUserLogout(state) {
      return {
        ...initialState,
        theme: state.theme,
      };
    },
    authTokenLoading(state, action) {
      const { payload } = action;
      return {
        ...state,
        token_loading: Boolean(payload?.loading),
      };
    },
  },
});

// Export action creators as named exports
export const {
  addAuthToken,
  addAuthUser,
  changeTheme,
  changeVoiceEnable,
  changeProcessTips,
  changeProcessTimer,
  changeVoiceType,
  saveGoal,
  saveDeadline,
  authUserLoading,
  authTokenLoading,
  authUserLogout,
  changeIntegrationQuesEnable,
  changeInstructionsEnable,
} = authSlice.actions;

// Export reducer as default export
export default authSlice.reducer;
