import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
/*import { googleLogout, useGoogleLogin } from "@react-oauth/google";*/
import { login } from "../redux/actions";
import { useState, useEffect } from "react";
import {
  StyledForm,
  StyledFormBack,
  StyledFormImg,
  StyledFormFont1,
  StyledFormFont2,
  StyledFormLeftWrap,
  StyledFormRightWrap,
} from "../components/Form";

const loginImg = require("../assets/images/login.png");
const googleImg = require("../assets/images/google.png");
const facebookImg = require("../assets/images/facebook.png");

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginLoading = useSelector(({ auth }) => auth.user_loading);
  /*const [user, setUser] = useState([]);*/
  const [profile, setProfile] = useState([]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email address is invalid")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values, actions) => {
      // alert(JSON.stringify(values, null, 2));
      function alterFormToAPIResult(error, success) {
        console.log("Navigate!");

        if (error) {
          actions.setFieldTouched("password", false);
          actions.setFieldValue("password", "");
        }
        if (success) {
          console.log("Navigate!");
          navigate("/chat");
        }
      }
      dispatch(login(values, alterFormToAPIResult));
    },
  });
  /*
  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  */
  /*
  useEffect(() => {
    if (user) {
      console.log("user - ", user);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("profile - ", res.data);
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);
  */

  return (
    <StyledFormBack>
      <StyledForm>
        <Row>
          <Col className="border-right">
            <StyledFormLeftWrap>
              <StyledFormImg src={loginImg} />
              <StyledFormFont2>
                The Enlightenment Therapy app guides you to resolve deep
                personal issues at the root cause level while raising your
                consciousness to a level of spiritual enlightenment.
              </StyledFormFont2>
            </StyledFormLeftWrap>
          </Col>
          <Col>
            <StyledFormRightWrap>
              <h2 className="text-uppercase mb-3">Login</h2>

              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Email"
                    tabIndex={1}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <Form.Text className="text-danger">
                      {formik.errors.email}
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <div className="position-relative">
                    <Form.Text
                      className="text-custom m-0 text-decoration-none"
                      style={{
                        position: "absolute",
                        right: 0,
                        transform: "translateY(-100%)",
                      }}
                      as={Link}
                      to="/recoverpass"
                      tabIndex={5}
                    >
                      Forgot password?
                    </Form.Text>
                    <Form.Control
                      id="password"
                      name="password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Password"
                      tabIndex={2}
                    />
                  </div>

                  {formik.touched.password && formik.errors.password ? (
                    <Form.Text className="text-danger">
                      {formik.errors.password}
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 bg-custom border-0"
                  disabled={loginLoading}
                  tabIndex={3}
                >
                  {loginLoading ? "Loading..." : "Continue"}
                </Button>

                <p className="text-muted fst-italic mt-1">
                  Don't have an account yet?{" "}
                  <Link to="/signup" tabIndex={4} className="text-custom">
                    Register here
                  </Link>
                </p>
                {/*
          <Button type="button" onClick={() => loginGoogle()} className="d-block w-100 mb-3 text-start">
            <img src={googleImg} className="mx-2" />
            Continue with google
          </Button>
          <Button type="button"  className="d-block w-100 text-start">
            <img src={facebookImg} className="mx-2" />
            Continue with Facebook
          </Button>
          */}
              </Form>
            </StyledFormRightWrap>
          </Col>
        </Row>
      </StyledForm>
    </StyledFormBack>
  );
}

export default Login;
