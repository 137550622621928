import styled from "styled-components";
import {
  StyledChat,
  StyledChatContent,
  ChatSidebar,
  ChatInput,
  StyledChatFooter,
} from "../../components/Chat";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { ReactComponent as LogoSvg } from "../../assets/images/logo.svg";
import { ReactComponent as ProtocolSvg } from "../../assets/images/protocol.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/arrow_right.svg";
import { ReactComponent as BackIcon } from "../../assets/images/arrow_back.svg";

const StyledChatMain = styled.div`
  position: relative;
  height: 100%;
`;

const StyledChatSplash = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 15px;

`;

const StyledChatSplashBody = styled.div`
  max-width: 960px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 29px;
  padding: 29px 47px;

  border-radius: 35px;
  border-width: 1px;
  border-style: solid;

  @media (max-width: 1024px) {
    padding: 0px;
    border-radius: 0px;
    border-width: 0px;
  }
`;

const StyledChatSplash2 = styled.div`
  max-width: 1040px;
  width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 60px;
`;
const StyledChatLogoImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.text};
  span {
    font-family: "clash display";
    font-size: 28px;
    font-weight: 500;
  }
  svg {
    width: 60px;
    height: 60px;
    margin-right: 18px;
    color: ${({ theme }) => theme.logo};
  }

  @media (max-width: 1024px) {
    margin-bottom: 45px;
    display: none !important;
  }
`;
const StyledChatProtocolImg = styled.span`
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.logo};

  @media (max-width: 575px) {
    margin-top: 40px;
  }
`;

const StyledChatTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.text};
  width: 100%;
  display: inline-block;

  @media (max-width: 575px) {
    font-size: 30px;
  }
`;

const StyledChatDescription = styled.span`
  font-style: normal;
  margin: 20px;
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.text};
  width: 100%;
  display: inline-block;

  @media (max-width: 575px) {
    font-size: 15px;
  }
`;

const StyledDivider = styled.div`
  border-bottom: 1px solid #fff;
`;

const StyledChatProtocol = styled.a`
  cursor: pointer;
  background: ${({ theme }) => theme.protocol};
  border-radius: 10px;
  padding: 16px 20px;
  text-align: center;
  max-width: 305px;
  width: 100%;
  color: ${({ theme }) => theme.text};

  @media (max-width: 960px) {
    max-width: unset;
  }
`;

const StyledChatProtocolTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
`;
const StyledChatProtocolDescription = styled.span`
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
`;

const StyledChatMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 29px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledChatMenuItem = styled.div`
  background: ${({ theme }) => theme.protocol};
  border-radius: 12px;

  cursor: pointer;
  padding: 16px 24px;
  display: flex;
  gap: 16px;
`;

const StyledChatMenuItemText = styled.div`
  flex: 1;
`;

const StyledChatMenuDivider = styled.div`
  border-right: 1px solid #fff;
`;

const StyledChatMenuIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  fill: ${({ theme }) => theme.text};
`;

const StyledBackButton = styled.div`
  position: absolute;
  top: -40px;
  left: -10px;

  display: flex;
  gap: 10px;
  cursor: pointer;

  z-index: 99;
  svg {
    fill: ${({ theme }) => theme.text};
  }

  @media (max-width: 768px) {
    z-index: unset;
    top: -20px;
    left: 0px;
  }
`;

const StyledBackButtonText = styled.div`
  font-size: 25px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 3px;
  }
`;

const StyledChatProtocolWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  grid-auto-rows: minmax(50px, auto);
  gap: 29px;
  justify-content: center;
  margin-top: 29px;


  @media (max-width: 960px) {
    grid-template-columns: unset;
  }
`;

const StyledChatProtocolFlexWrapper = styled.div`
  display: flex;

  gap: 29px;
  justify-content: center;
  margin-top: 29px;

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: unset;
  }
`;

const ChatProtocol = (props: any) => {
  return (
    <StyledChatProtocol href={`/chat/box?method=${props.href}`}>
      <StyledChatProtocolTitle>{props.method}</StyledChatProtocolTitle>
      <StyledChatProtocolDescription>
        {" - "}
        {props.desc}
      </StyledChatProtocolDescription>
    </StyledChatProtocol>
  );
};

const TrainingProtocol = (props: any) => {
  return (
    <StyledChatProtocol
      href={`https://enlightenmenttherapy.co/appresources`}
      target="_blank"
    >
      <StyledChatProtocolTitle>Training Resources</StyledChatProtocolTitle>
      <StyledChatProtocolDescription>
        {" - "}
        Learn the protocols first before using them
      </StyledChatProtocolDescription>
    </StyledChatProtocol>
  );
};

export default function Chat() {
  const [selectedMenu, setSelectedMenu] = useState(0);
  return (
    <StyledChat>
      <ChatSidebar />
      <StyledChatContent>
        <StyledChatMain>
          {selectedMenu == 0 && (
            <StyledChatSplash>
              <StyledChatSplashBody>
                <StyledChatTitle>
                  Welcome, choose your experience below
                </StyledChatTitle>
                <StyledDivider></StyledDivider>
                <StyledChatMenu>
                  <StyledChatMenuItem
                    onClick={() => {
                      setSelectedMenu(1);
                    }}
                  >
                    <StyledChatMenuItemText>
                      <StyledChatProtocolTitle>
                        Neutralize Emotional Charge
                      </StyledChatProtocolTitle>
                      <div>
                        <StyledChatProtocolDescription>
                          Neutralize emotional charge and quickly return to a
                          relaxed state
                        </StyledChatProtocolDescription>
                      </div>
                    </StyledChatMenuItemText>
                    <StyledChatMenuDivider></StyledChatMenuDivider>
                    <StyledChatMenuIcon>
                      <MenuIcon></MenuIcon>
                    </StyledChatMenuIcon>
                  </StyledChatMenuItem>

                  <StyledChatMenuItem
                    onClick={() => {
                      setSelectedMenu(2);
                    }}
                  >
                    <StyledChatMenuItemText>
                      <StyledChatProtocolTitle>
                        Release Negative Patterns
                      </StyledChatProtocolTitle>
                      <div>
                        <StyledChatProtocolDescription>
                          Release inner conflicts and negative patterns
                        </StyledChatProtocolDescription>
                      </div>
                    </StyledChatMenuItemText>
                    <StyledChatMenuDivider></StyledChatMenuDivider>
                    <StyledChatMenuIcon>
                      <MenuIcon></MenuIcon>
                    </StyledChatMenuIcon>
                  </StyledChatMenuItem>

                  <StyledChatMenuItem
                    onClick={() => {
                      setSelectedMenu(3);
                    }}
                  >
                    <StyledChatMenuItemText>
                      <StyledChatProtocolTitle>
                        Experience Oneness
                      </StyledChatProtocolTitle>
                      <div>
                        <StyledChatProtocolDescription>
                          Reach a state of pure consciousness
                        </StyledChatProtocolDescription>
                      </div>
                    </StyledChatMenuItemText>
                    <StyledChatMenuDivider></StyledChatMenuDivider>
                    <StyledChatMenuIcon>
                      <MenuIcon></MenuIcon>
                    </StyledChatMenuIcon>
                  </StyledChatMenuItem>
                </StyledChatMenu>
              </StyledChatSplashBody>
            </StyledChatSplash>
          )}

          {selectedMenu != 0 && (
            <StyledBackButton onClick={() => setSelectedMenu(0)}>
              <BackIcon></BackIcon>
              <StyledBackButtonText>Back</StyledBackButtonText>
            </StyledBackButton>
          )}

          {selectedMenu == 3 && (
            <StyledChatSplash2>
              <StyledChatTitle style={{ textAlign: "center" }}>
                Experience Oneness
              </StyledChatTitle>
              <StyledChatDescription>
                Choose a protocol to get started
              </StyledChatDescription>
              <StyledChatProtocolWrapper>
                <ChatProtocol
                  method="Satori Trainer"
                  desc="Start here if you’re new"
                  href="satori_trainer"
                />
                <ChatProtocol
                  method="Satori 1"
                  desc="Release concepts & experience enlightenment"
                  href="satori1"
                />
                <ChatProtocol
                  method="Satori 2"
                  desc="Integrate polarities & experience enlightenment"
                  href="satori2"
                />
                <ChatProtocol
                  method="Satori 3"
                  desc="Neutralize emotional charge & experience enlightenment"
                  href="satori3"
                />
                <ChatProtocol
                  method="Satori 4"
                  desc="Integrate emotions & experience enlightenment"
                  href="satori4"
                />
              </StyledChatProtocolWrapper>
            </StyledChatSplash2>
          )}

          {selectedMenu == 2 && (
            <StyledChatSplash2>
              <StyledChatTitle style={{ textAlign: "center" }}>
                Release Negative Patterns
              </StyledChatTitle>
              <StyledChatDescription>
                Choose a protocol to get started
              </StyledChatDescription>
              <StyledChatProtocolFlexWrapper>
                <ChatProtocol
                  method="IP Basic"
                  desc="Use this if you’re new"
                  href="ip_basic"
                />
                <ChatProtocol
                  method="IP Standard"
                  desc="Use this for deeper integrations"
                  href="ip_standard"
                />
                </StyledChatProtocolFlexWrapper>
                <StyledChatProtocolFlexWrapper>
                <ChatProtocol
                  method="IP Advanced"
                  desc="Use this once you’re a pro"
                  href="ip_advanced"
                />
              </StyledChatProtocolFlexWrapper>
            </StyledChatSplash2>
          )}

          {selectedMenu == 1 && (
            <StyledChatSplash2>
              <StyledChatTitle style={{ textAlign: "center" }}>
                Neutralize Emotional Charge
              </StyledChatTitle>
              <StyledChatDescription>
                Choose a protocol to get started
              </StyledChatDescription>
              <StyledChatProtocolFlexWrapper>
                <ChatProtocol
                  method="Universal"
                  desc="Start here if you’re new"
                  href="universal"
                />
                <ChatProtocol
                  method="DP2"
                  desc="Use this if you have trouble with Universal"
                  href="dp2"
                />
                </StyledChatProtocolFlexWrapper>
              <StyledChatProtocolFlexWrapper>
                <ChatProtocol
                  method="IP Simplified"
                  desc="Use this for deeper releases"
                  href="ip_simplified"
                />
                </StyledChatProtocolFlexWrapper>
            </StyledChatSplash2>
          )}
          {/* <StyledChatProtocolWrapper>
              <TrainingProtocol />
              
              
              
            </StyledChatProtocolWrapper> */}
        </StyledChatMain>

        {/* <StyledChatFooter>
          <ChatInput
            placeholder="Please select protocol to begin"
            disabled={true}
            
            func={null}
          />
        </StyledChatFooter> */}
      </StyledChatContent>
    </StyledChat>
  );
}
