
import styled from "styled-components";

const backImg = require("../assets/images/back.png");

export const StyledFormImg = styled.img`
  width: 100%;
  margin-bottom: 50px;
`;

export const StyledFormFont1 = styled.p`
  font-size: 40px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 25px;
  max-width: 504px;

  @media (min-width: 576px) {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 992px) {
    font-size: 32px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
  @media (min-width: 1440px) {
    font-size: 40px;
  }
`;


export const StyledFormHeading2 = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
`;

export const StyledFormText2 = styled.p`
  color: rgba(255, 255, 255, 0.80);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`;

export const StyledFormFont2 = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  max-width: 515px;
`;

export const StyledFormRightWrap = styled.div`
  max-width: 410px;
  width: 100%;
`;


export const StyledFormLeftWrap = styled.div`
  max-width: 515px;
  width: 100%;
  
  @media (max-width: 768px) {
    max-width: 410px;
  }
`;


export const StyledFormBack = styled.div`
  background-image: ${({ theme }) => `url(${theme.form_back})`};
  background-position: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: unset;
    padding-top: 0px;
  }
`;

export const StyledForm = styled.div`
  background: ${({ theme }) => theme.form};
  color: ${({ theme }) => theme.text};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;  
  width: 80%; 
  max-width: 1439px;
  padding: 80px 0;
  

  >.row{
    margin: 0px;
  }

  >.row>.col{
    padding: 0px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .border-right{
    border-right: solid 1px ${({ theme }) => theme.input};
  }

  
  @media (max-width: 1440px) {
    padding: 50px 0;

    >.row>.col{
      padding: 0px 70px;
    }
  }
  
  @media (max-width: 1080px) {
    padding: 30px 0;
    width: 85%;

    >.row>.col{
      padding: 0px 30px;
    }
  }

  
  @media (max-width: 768px) {
    width: 100%; 
    border-radius: 0px;  
    height: 100%;
    padding-top: 150px;

    >.row{
    flex-direction: column;
    gap: 70px;
    }
  }
`;

export const StyledSmallForm = styled.div`
  background: ${({ theme }) => theme.form};
  color: ${({ theme }) => theme.text};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;  
  width: 100%; 
  max-width: 500px;
  padding: 30px 70px;
  
  margin: 30px;

  >.row>.col{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  @media (max-width: 768px) {
    padding: 30px 30px;
  }
`;

export const StyledCardWrap = styled.div`
  width: 100%;
`;