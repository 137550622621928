import styled from "styled-components";

const arrowImg = require("../assets/images/arrow.png");

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding 0 30px;    
  font-size: 16px;
  line-height: 180.5%;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
`;

const StyledButton = styled.a`
        display: flex;
        align-items: center;
        justify-content: center;
        padding 0 30px;    
        height: 52px;
        font-weight: 400;
        font-size: 16px;
        line-height: 180.5%;
        background: #FFFFFF;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: black;
        cursor: pointer;
        white-space: nowrap;
        max-width: 230px;
        text-decoration: none !important;
        color: #111928;        
        font-family: 'Clash Display', sans-serif;

        &.btn-gradient{
          background: ${({ theme }) => theme.logo};
          color: #101E21  !important;
          width: 100%;
          max-width: initial;
        }
        
        &.btn-outline{
            outline: solid 1px ${({ theme }) => theme.text};
            background: transparent;
            color: ${({ theme }) => theme.text};
        }

        &.btn-undefined, &.btn-arrow{
          color: #111928;
        }
        &.btn-normal{
          background: ${({ theme }) => theme.text};
          color: ${({ theme }) => theme.sidebar};
        }

        &.btn-home{
          background: ${({ theme }) => theme.form};
          color: ${({ theme }) => theme.home_text};
          border-radius: 8px;
          font-weight: 500;
          font-size: 16px;
          max-width: fit-content;
        }

        &.btn-arrow:after{
            content: '';
            background: url(${arrowImg});
            width: 14px;
            height: 14px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: 10px;
        }
        &.btn-arrow:hover{
          color: #111928 !important;
        }
        &.btn-border-bottom{
            position: relative;
        }
        &.btn-border-bottom.active:after, &.btn-border-bottom:hover:after{
            content: " ";
            background: white;
            width: 100%;
            height: 3px;
            position: absolute;
            bottom: -20px;
            left: 0;
        }

        &:hover, &:active{
          font-weight: 700;          
        }

        @media (max-width: 575px) {
            padding: 0 20px;

            &.btn-border-bottom{
                padding:0;
            }
        }

        @media (max-width: 450px) {
          padding: 0 16px;
        }

        @media (max-width: 400px) {
          padding: 0 10px;
        }
    `;

export const CustomButton = (props: any) => {
  return (
    <StyledButton
      className={`btn-${props.type} ${props.className}`}
      href={props.href ? props.href : "#"}
      onClick={props.onClick}
      target={props.target ? props.target : ""}
    >
      {props.text}
    </StyledButton>
  );
};