import styled from "styled-components";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { useState, useEffect } from "react";
import { setIn } from "formik";
import { useSelector } from "react-redux";

import { ReactComponent as LogoSvg } from "../assets/images/logo.svg";
import { ReactComponent as NewSvg } from "../assets/images/new.svg";
import { ReactComponent as MessageSvg } from "../assets/images/message.svg";
import { ReactComponent as SendSvg } from "../assets/images/send.svg";
import { ReactComponent as TelegramSvg } from "../assets/images/telegram.svg";

const StyledLogoImg = styled.span`
  background-image: ${({ theme }) => `url(${theme.logo_img})`};
  background-position: center;
  width: 100%;
  height: 60px;
  background-size: 100%;
  background-repeat: no-repeat;
`;

export const StyledChat = styled.div`
  display: flex;
  min-height: 100vh;
`;
export const StyledChatContent = styled.div`
  position: relative;
  width: 100%;
  margin: 24px 40px;
  border-radius: 20px;
  padding: 60px 30px 30px 30px;
  background: ${({ theme }) => `${theme.chatback}`};

  @media (max-width: 768px) {
    margin: 90px 10px 10px 10px;
    padding: 30px 0px 0px 0px;
  }
`;
export const StyledChatFooter = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 30px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.input};
  display: flex;
  gap: 30px;

  @media (max-width: 1450px) {
    position: relative;
    margin-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 575px) {
    position: relative;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const StyledChatInput = styled.div`
  position: relative;
  height: 55px;
  width: 100%;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.input};
  border-radius: 8px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  & > input {
    border: none;
    outline: none;
    width: 100%;
    color: ${({ theme }) => theme.text};
    background: none;
  }
`;
export const StyledChatInputIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  right: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.logo};
`;

export const ChatInput = (props: any) => {
  const [input, setInput] = useState("");

  const onChangeFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = (e.target as HTMLInputElement).value;
    setInput(val);
    props.change(val);
  };

  const onKeyUpFunc = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      props.func(input);
      setInput("");
    }
  };

  const onClickFunc = () => {
    props.func(input);
    setInput("");
  };

  return (
    <StyledChatInput>
      {props.disabled && (
        <>
          <input
            autoFocus
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          <StyledChatInputIcon>
            <SendSvg />
          </StyledChatInputIcon>
        </>
      )}
      {!props.disabled && (
        <>
          <input
            placeholder={props.placeholder}
            disabled={props.disabled}
            onKeyUp={onKeyUpFunc}
            onChange={onChangeFunc}
            value={input}
            autoFocus
          />
          <StyledChatInputIcon onClick={onClickFunc}>
            <SendSvg />
          </StyledChatInputIcon>
        </>
      )}
    </StyledChatInput>
  );
};
const menuData = [
  // { url: "/chat", text: "Select Process", method: "" },
  {
    url: "/chat/box",
    text: "Universal",
    method: "universal",
  },
  {
    url: "/chat/box",
    text: "IP Simplified",
    method: "ip_simplified",
  },
  { url: "/chat/box", text: "DP2", method: "dp2" },
  {
    url: "/chat/box",
    text: "IP Basic",
    method: "ip_basic",
  },
  {
    url: "/chat/box",
    text: "IP Standard",
    method: "ip_standard",
  },
  {
    url: "/chat/box",
    text: "IP Advanced",
    method: "ip_advanced",
  },
  {
    url: "/chat/box",
    text: "Satori Trainer",
    method: "satori_trainer",
  },
  {
    url: "/chat/box",
    text: "Satori 1",
    method: "satori1",
  },
  {
    url: "/chat/box",
    text: "Satori 2",
    method: "satori2",
  },
  {
    url: "/chat/box",
    text: "Satori 3",
    method: "satori3",
  },
  {
    url: "/chat/box",
    text: "Satori 4",
    method: "satori4",
  },
  // { url: "/chat/box", text: "Aspectics", method: "aspect" },
];

export const ChatSidebar = (props: any) => {
  const location = useLocation();
  const [method, setMethod] = useState("");
  const [menuItems, setMenuItems] = useState(menuData);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    let m = searchParams.get("method");
    // if(!user.billing.isAspect) {
    //   setMenuItems(menuData.filter((element) => element.method != 'aspect'));
    // }
    setMethod(m!);
  }, [searchParams]);

  return (
    <Sidebar width="300px" breakPoint="md">
      <Menu>
        <MenuItem
          className="sidebar-logo"
          icon={<StyledLogoImg />}
          component={<Link to="/chat" />}
        ></MenuItem>
        <MenuItem key={-1} icon={<NewSvg />} component={<Link to={"/chat"} />}>
          {" "}
          {"Select Process"}
        </MenuItem>
        <MenuItem
          icon={<MessageSvg />}
          component={
            <a
              href="https://enlightenmenttherapy.co/appresources"
              target="_blank"
            ></a>
          }
        >
          {" "}
          Training Resources
        </MenuItem>

        {menuItems.map((item, i) => {
          return (
            <MenuItem
              key={i}
              active={
                location.pathname &&
                location.pathname == item.url &&
                (item.method === "" || method === item.method)
                  ? true
                  : false
              }
              icon={item.method === "" ? <NewSvg /> : <MessageSvg />}
              component={
                <Link
                  to={
                    item.method === ""
                      ? item.url
                      : item.url + "?method=" + item.method
                  }
                />
              }
            >
              {" "}
              {item.text}
            </MenuItem>
          );
        })}

        <MenuItem icon={<TelegramSvg />}

          component={
            <a
              href="https://enlightenmenttherapy.co/etgroup"
              target="_blank"
            ></a>
          }
        >
          {" "}
          Live Support Group
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};
