import { createGlobalStyle } from "styled-components";
const backImg_d = require("./assets/images/back.png");
const backImg_l = require("./assets/images/back_l.png");

const logoImg_d = require("./assets/images/logo_text_d.png");
const logoImg_l = require("./assets/images/logo_text_l.png");

export const lightTheme = {
  body: "#E4ECEC",  
  chatback: "#F6FFFF",
  text: "#17272B",  
  sidebar: "#E4ECEC",
  input: "#D2D8D8",
  logo: "#2A88BF",
  chat_bot: "#EAF3F3",
  protocol: "#e4ecec",
  chat_user: "#DDE5E5",
  sidebar_active: "linear-gradient(89.85deg, #D8E6E6 0.13%, #F6FFFF 269.55%)",
  sidebar_hover:
    "linear-gradient(89.85deg, #D8E6E6b0 0.13%, #F6FFFFb0 269.55%)",
  form: "#F1FAFA",
  home_text: "#101E21",
  form_back: backImg_l,
  logo_img: logoImg_l,
  border: "#c4dCdC"
};
export const darkTheme = {
  body: "#17272B",
  chatback: "#213135",
  text: "#fff",
  sidebar: "#17272B",
  input: "#587278",
  logo: "#7EC4C9",
  protocol: "#25363A",
  chat_bot: "#182D33",
  chat_user: "#0F1B1F",
  sidebar_active: "linear-gradient(89.85deg, #2F4246 0.13%, #000000 269.55%)",
  sidebar_hover:
    "linear-gradient(89.85deg, #2F424640 0.13%, #00000040 269.55%)",
  form: "#17272B",
  home_text: "#fff",
  form_back: backImg_d,
  logo_img: logoImg_d,
  border: "#31464A"
};

export const ThemeStyles = createGlobalStyle`
    * {
      border-color:  ${({ theme }) => theme.border} !important;
    }
    body {
      background: ${({ theme }) => theme.body};
      color: ${({ theme }) => theme.text};
    }
    .chatboard-container{
      background: ${({ theme }) => theme.board};
    }
    .ps-sidebar-container{
      background: ${({ theme }) => theme.sidebar} !important;
    }
    .form-control {
      background: transparent;
      border: 1px solid #4b5563;
      border-radius: 8px;
      height: 42px;
      color: ${({ theme }) => theme.text};
    }
    #responsive-navbar-nav a.nav-link {
      color: ${({ theme }) => theme.text};
    }
    a.link-text{
      color: #229255;
    }
    a.link-text:hover{
      color: #DCE11F !important;
    }
    .sidebar-logo .ps-menu-label {
      font-weight: 600;
      color: ${({ theme }) => theme.text};
      font-size: 20px;
    }

    .modal-content {
      background: ${({ theme }) => theme.form};
      border: 0.5px solid ${({ theme }) => theme.input};;
      border-radius: 8px;
    }
    .list-group-item{
      color: ${({ theme }) => theme.text};
    }
    .sidebar-logo .ps-menu-icon{
      color: ${({ theme }) => theme.logo};
    }
    .nav-item .dropdown-menu{
      background: ${({ theme }) => theme.form};
    }
        
    .nav-item .dropdown-menu .dropdown-item{
      color: ${({ theme }) => theme.text};
    }
    .ps-menuitem-root .ps-menu-button.ps-active {
      background: ${({ theme }) => theme.sidebar_active} !important;
    }
    .ps-menuitem-root .ps-menu-button:hover {
      background: ${({ theme }) => theme.sidebar_hover} !important;
    }

    .text-custom, a:hover{
      color: ${({ theme }) => theme.logo} !important;
    }
    
    .text-custom, a.ps-active{
      color: ${({ theme }) => theme.logo} !important;
    }

    .bg-custom{
      background-color: ${({ theme }) => theme.logo} !important;
    }
    
    .btn-close:after {
      content: "×";
      position: relative;
      top: -4px;
      color: ${({ theme }) => theme.text};      
      font-size: 24px;
      line-height: 100%;
    }

    @media (max-width: 575px) {
      #responsive-navbar-nav {
        background: ${({ theme }) => theme.form};
        box-shadow: 0px 1px 2px ${({ theme }) => theme.body};
      }
    }
`;
