import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { StyledCardWrap, StyledForm, StyledFormBack, StyledSmallForm } from "../components/Form";
import { Row, Col } from "react-bootstrap";

const Profile = () => {
  const user = useSelector((state: any) => state.auth.user);

  return (
    <StyledFormBack>
      <StyledSmallForm>
        <Row>
          <Col>
              <h2 className="text-center m-4">Your Profile</h2>
              <StyledCardWrap>
              <div className="card position-relative">
                <div className="card-header">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div>
                      <span className="card-label">First Name: </span>
                      <span>{user?.firstName}</span>
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div>
                      <span className="card-label">Last Name: </span>
                      <span>{user?.lastName}</span>
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div>
                        <span className="card-label">Email: </span>
                        <span>{user?.email}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              </StyledCardWrap>
              <Link to="/" className="btn bg-custom border-0 my-2">
                Back
              </Link>
          </Col>
        </Row>
      </StyledSmallForm>
    </StyledFormBack>
  );
};

export default Profile;
