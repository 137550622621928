import styled from "styled-components";

const StyledModalText1 = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  color: ${({ theme }) => theme.text};
  margin-bottom: 10px;
`;

const StyledModalText2 = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.text};
  margin-bottom: 25px;
`;

const StyledModalContent = styled.div`
  border: 0.5px solid ${({ theme }) => theme.input};
  border-radius: 8px;
  padding: 16px;
`;
const StyledChatHistoryItemText = styled.span`
  white-space: pre-line;
  margin-left: 56px;
  position: relative;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  line-height: 200%;
  background: ${({ theme }) => theme.chat_user};

  &.chat-bot-text {
    background: ${({ theme }) => theme.chat_bot};
    margin-left: 11px;
  }

  @media (max-width: 575px) {
    margin-bottom: 0px;
  }
`;
const StyledChatHistoryItem = styled.div`
  display: flex;

  @media (max-width: 575px) {
    padding: 8px;
    line-height: 150%;
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

const StyledChatLogo = styled.span`
  width: 45px;
  height: 45px;
  border: 1px solid ${({ theme }) => theme.logo};
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 45px;
  color: ${({ theme }) => theme.logo};
`;

export {
    StyledModalText1,
    StyledChatHistoryItem,
    StyledChatLogo,
    StyledModalContent,
    StyledChatHistoryItemText,
    StyledModalText2,
}