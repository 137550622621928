import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  StyledForm,
  StyledFormBack,
  StyledFormImg,
  StyledFormFont1,
  StyledFormFont2,
  StyledFormHeading2,
  StyledFormText2,
  StyledFormRightWrap,
  StyledFormLeftWrap,
} from "../components/Form";
import styled from "styled-components";

import { signup } from "../redux/actions";
const loginImg = require("../assets/images/login.png");

export const StyledTermText = styled.div`
  color: #9ca3af;
  margin-top: 16px;
  font-size: 14px;

  a {
    color: #9ca3af;
    text-decoration: underline;
    font-weight: 500;
  }
`;

function Signup() {
  const dispatch = useDispatch();
  const signupLoading = useSelector((state) => state.auth.user_loading);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(4, "Must be 4 characters or more")
        .required("Password is required"),
      passwordConfirm: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values, actions) => {
      // alert(JSON.stringify(values, null, 2));
      function alterFormToAPIResult(error, success) {
        if (error) {
          actions.setFieldTouched("password", false);
          actions.setFieldValue("password", "");

          actions.setFieldTouched("passwordConfirm", false);
          actions.setFieldValue("passwordConfirm", "");
        }
      }
      dispatch(signup(values, alterFormToAPIResult));
    },
  });

  return (
    <StyledFormBack>
      <StyledForm>
        <Row>
          <Col className="border-right">
            <StyledFormLeftWrap>
              <StyledFormImg src={loginImg} />
              <StyledFormFont2>
                The Enlightenment Therapy app guides you to resolve deep
                personal issues at the root cause level while raising your
                consciousness to a level of spiritual enlightenment.
              </StyledFormFont2>
            </StyledFormLeftWrap>
          </Col>
          <Col>
            <StyledFormRightWrap>
              <StyledFormHeading2 className="mb-3">
                Create your account
              </StyledFormHeading2>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="First name"
                      />

                      {formik.touched.firstName && formik.errors.firstName ? (
                        <Form.Text className="text-danger">
                          {formik.errors.firstName}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Last name"
                      />

                      {formik.touched.lastName && formik.errors.lastName ? (
                        <Form.Text className="text-danger">
                          {formik.errors.lastName}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Email"
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <Form.Text className="text-danger">
                      {formik.errors.email}
                    </Form.Text>
                  ) : (
                    <Form.Text className="text-muted">
                      Use the same email you used to purchase the training.
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Password"
                  />

                  {formik.touched.password && formik.errors.password ? (
                    <Form.Text className="text-danger">
                      {formik.errors.password}
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    id="passwordConfirm"
                    name="passwordConfirm"
                    type="password"
                    value={formik.values.passwordConfirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Confirm Password"
                  />

                  {formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm ? (
                    <Form.Text className="text-danger">
                      {formik.errors.passwordConfirm}
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={signupLoading}
                  className="w-100 bg-custom border-0"
                >
                  {signupLoading ? "Loading..." : "Continue"}
                </Button>
                <StyledTermText>
                  By clicking “Continue” above, you acknowledge that you have
                  read and understood, and agree to our{" "}
                  <a
                    target="_blank"
                    href="https://enlightenmenttherapy.co/appterms"
                  >
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://enlightenmenttherapy.co/appprivacy"
                  >
                    Privacy Policy
                  </a>
                </StyledTermText>
              </Form>
            </StyledFormRightWrap>
          </Col>
        </Row>
      </StyledForm>
    </StyledFormBack>
  );
}

export default Signup;
