import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { StyledForm, StyledFormBack } from "../components/Form";
import styled from "styled-components";

const Lost = () => {
  return (
    <StyledFormBack>
      <StyledForm>
        <span>
          <h1 className="text-uppercase text-center">Page not found!</h1>
          <h4 className="lead text-center">
            You are seeing this because you are headed into abyss!
          </h4>
          <p className="text-muted text-center mt-3">
            <Link to="/"  className="btn bg-custom border-0 my-2">
              Back
            </Link>
          </p>
        </span>
      </StyledForm>
    </StyledFormBack>
  );
};

export default Lost;
