import { useContext } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import styled from "styled-components";
import { CustomButton, StyledLink } from "./Button";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { default as ToggleSwitch } from "react-switch";
import { logout, logoutEveryDevice } from "../redux/actions";
import { AuthenticationContext } from "../context/authenticationContext";
import { closeNotify } from "../redux/features/notify/notifySlice";
import { changeTheme } from "../redux/features/auth/authSlice";
import { ReactComponent as LogoSvg } from "../assets/images/logo.svg";
import { ReactComponent as LightSvg } from "../assets/images/light.svg";
import { ReactComponent as DarkSvg } from "../assets/images/dark.svg";
import { NavItem, NavLink } from "react-bootstrap";

function NavBar() {
  const dispatch = useDispatch();
  const { userIsAuthenticated } = useContext(AuthenticationContext);
  const theme = useSelector((state) => state.auth.theme);

  const onThemeSwitch = () => {
    //return;
    dispatch(changeTheme({ theme: !theme }));
    localStorage.setItem("theme", theme === false ? "light" : "dark");
  };

  const StyledLogoImg = styled.span`
    background-image: ${({ theme }) => `url(${theme.logo_img})`};
    background-position: center;
    width: 200px;
    height: 60px;
    background-size: 100%;
    background-repeat: no-repeat;
  `;

  const StyledThemeSvg = styled.span`
    svg {
      fill: ${({ theme }) => theme.logo};
    }
  `;

  const StyledNavbarLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.text};
    span {
      font-family: "clash display";
      font-size: 24px;
      font-weight: 500;
    }
    svg {
      margin-right: 14px;
      color: ${({ theme }) => theme.logo};
    }
  `;

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      bg="transparent"
      variant="light"
      className="px-3 py-3 px-md-5 py-md-4 justify-content-between w-100 position-absolute"
    >
      <Navbar.Brand as={Link} to="/chat" tabIndex={1} rel="opener">
        <StyledNavbarLogo>
          <StyledLogoImg />
        </StyledNavbarLogo>
      </Navbar.Brand>
      {userIsAuthenticated ? (
        <>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={theme ? "navbar-light" : "navbar-dark"}
          />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto d-none d-sm-flex justify-content-end align-items-sm-center">
              <ToggleSwitch
                className="theme-switch mx-3"
                onChange={onThemeSwitch}
                checked={theme ? true : false}
                checkedIcon={
                  <LightSvg
                    width={18}
                    height={18}
                    style={{ marginLeft: "5px" }}
                  />
                }
                uncheckedIcon={
                  <StyledThemeSvg>
                    <DarkSvg
                      width={18}
                      height={18}
                      style={{ marginLeft: "5px" }}
                    />
                  </StyledThemeSvg>
                }
                onColor="#fff"
                offColor="#121C20"
                onHandleColor="#C7D0D1"
                offHandleColor="#1A1918"
                handleDiameter={18}
              />
              <NavDropdown
                id="collasible-nav-dropdown"
                title="My Account"
                // tabIndex={1}
              >
                <NavDropdown.Item as={Link} to="/profile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/setting">
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item href="https://enlightenmenttherapy.co/etmanage" target="blank">
                  Billing
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item
                  as={Link}
                  onClick={() => {
                    dispatch(closeNotify());
                    dispatch(logout());
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
              {/* 
              <Nav.Link href="#sth" tabIndex={1}>
                Something here
              </Nav.Link>
              
              <NavDropdown
                title="Security"
                id="collasible-nav-dropdown-2"
                tabIndex={1}
              >
                <Button
                  as={NavDropdown.Item}
                  onClick={() => {
                    dispatch(closeNotify());
                    dispatch(logoutEveryDevice());
                  }}
                >
                  Log Out From All Devices
                </Button>
              </NavDropdown>
              */}
              {/*
              <Button
                as={Nav.Item}
                onClick={() => {
                  dispatch(closeNotify());
                  dispatch(logout());
                }}
                tabIndex={1}
              >
                Log Out
              </Button>
              */}
              {/* <CustomButton
                type="normal"
                text="Log Out"
                onClick={() => {
                  dispatch(closeNotify());
                  dispatch(logout());
                }}
              /> */}
            </Nav>
            <Nav className="me-auto d-flex d-sm-none flex-col align-items-center">
              <ToggleSwitch
                className="theme-switch mx-3"
                onChange={onThemeSwitch}
                checked={theme ? true : false}
                checkedIcon={
                  <LightSvg
                    width={18}
                    height={18}
                    style={{ marginLeft: "5px" }}
                  />
                }
                uncheckedIcon={
                  <StyledThemeSvg>
                    <DarkSvg
                      width={18}
                      height={18}
                      style={{ marginLeft: "5px" }}
                    />
                  </StyledThemeSvg>
                }
                onColor="#fff"
                offColor="#121C20"
                onHandleColor="#C7D0D1"
                offHandleColor="#1A1918"
                handleDiameter={18}
              />

              <NavDropdown.Item as={Link} to="/profile" className="text-center">
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/setting" className="text-center">
                Settings
              </NavDropdown.Item>
              <NavDropdown.Divider className="d-none d-sm-block" />

              <NavDropdown.Item
                as={Link}
                onClick={() => {
                  dispatch(closeNotify());
                  dispatch(logout());
                }}
                className="text-center"
              >
                Logout
              </NavDropdown.Item>
            </Nav>
          </Navbar.Collapse>
        </>
      ) : (
        <>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={theme ? "navbar-light" : "navbar-dark"}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="d-flex justify-content-end align-items-center login-buttons">
              <ToggleSwitch
                className="theme-switch mx-3"
                onChange={onThemeSwitch}
                checked={theme ? true : false}
                checkedIcon={
                  <LightSvg
                    width={18}
                    height={18}
                    style={{ marginLeft: "5px" }}
                  />
                }
                uncheckedIcon={
                  <StyledThemeSvg>
                    <DarkSvg
                      width={18}
                      height={18}
                      style={{ marginLeft: "5px" }}
                    />
                  </StyledThemeSvg>
                }
                onColor="#fff"
                offColor="#121C20"
                onHandleColor="#C7D0D1"
                offHandleColor="#1A1918"
                handleDiameter={18}
              />
              <StyledLink href="/signup">Sign Up</StyledLink>

              <StyledLink href="/login">Login</StyledLink>
            </div>
          </Navbar.Collapse>
        </>
      )}
    </Navbar>
  );
}

export default NavBar;
