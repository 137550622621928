import styled from "styled-components";
import { CustomButton } from "../components/Button";

const backImg = require("../assets/images/back.png");
const logoBackImg = require("../assets/images/logo_back.png");

const StyledHome = styled.div`
  background-image: ${({ theme }) => `url(${logoBackImg}), url(${theme.form_back})`};
  background-position: center;
  background-size: auto 80%, auto;
  background-repeat: no-repeat, repeat;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    background-size: 80% auto, cover;
  }
`;
const StyledHomeContent = styled.div`
  text-align: center;
  padding: 0 16px;
  max-width: 880px;
`;

const StyledText1 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 40px;
  color: ${({ theme }) => theme.logo};
  font-family: "Clash Display", sans-serif;

  @media (max-width: 575px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const StyledText2 = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 69px;
  line-height: 60px;
  color: ${({ theme }) => theme.text};
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Clash Display", sans-serif;

  @media (max-width: 575px) {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 30px;
  }
`;

const StyledText3 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 150%;
  color: ${({ theme }) => theme.home_text};
  font-family: "Clash Display", sans-serif;

  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 135%;
  }
`;

export default function Home() {
  return (
    <StyledHome>
      <StyledHomeContent>
        <div className="d-none d-sm-block">
          <CustomButton
            type="home"
            text="I am overwhelmed at work..."
            href="#"
            className="mx-5"
          />
          <CustomButton
            type="home"
            text="I struggle with my finances ..."
            href="#"
            className="float-end"
          />
        </div>
        <div className="clearfix mb-4 d-none d-sm-block"></div>
        <StyledText1>Faster than personal development & therapy</StyledText1>
        <StyledText2>
          Permanently Dissolve Emotional Challenges in 20 Minutes
        </StyledText2>
        <StyledText3>
          A breakthrough way to heal problems, unresolved emotional traumas, and
          stubborn blocks anytime, anywhere
        </StyledText3>
        <CustomButton
          type="arrow"
          text="Start now"
          href="/chat"
          className="mx-auto mt-5 bg-custom"
        />
        <div className="mt-4 d-none d-sm-block">
          <CustomButton
            type="home"
            text="I have trouble speaking my truth..."
            href="#"
            className=""
          />
          <CustomButton
            type="home"
            text="I can’t stop procrastinating... "
            href="#"
            className="float-end mx-5"
          />
        </div>
      </StyledHomeContent>
    </StyledHome>
  );
}
